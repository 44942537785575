import Component from '../../../../utils/store/lib/component'

export const SEQUENTIAL_COMPONENT = '#sequential';

export default class Sequential extends Component {
  constructor(props = {}) {
    super(props);

    $(SEQUENTIAL_COMPONENT).on('change', (e) => {
      this.store.commit('sequential', e.target.checked);
      if (this.store.state.isDelta) { props.store.events.publish('fetchSamplesData'); }
      props.store.events.publish('runCalculator');
    });
  }

  initialize() {
    this.store.commit('sequential', $(SEQUENTIAL_COMPONENT).prop('checked'));
  }
}
