// should be imported first to ensure that other imports' errors are captured
import "./sentry";
import "./global";

import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";
import "@nathanvda/cocoon";
import "trix";
import "@rails/actiontext";

/*
  See https://jqueryui.com/download/
  We need Sortable & Selectable.
  Unselect all of the components, and then select "Selectable" and "Sortable".
  This page will show you the other dependencies required
  (e.g. ":data Selector", "Mouse", etc.). Import these dependencies as well.
*/
import "jquery-ui";
import "jquery-ui/ui/data";
import "jquery-ui/ui/scroll-parent";
import "jquery-ui/ui/widgets/mouse";
import "jquery-ui/ui/widgets/sortable";
import "jquery-ui/ui/widgets/selectable";

import "waypoints/lib/jquery.waypoints";

import "jquery-resizable-columns";

import "./bootstrap";
import "./common";

// Disable turbo for forms by default for a smoother migration from turbolinks to turbo:
// - https://turbo.hotwired.dev/handbook/drive#disabling-turbo-drive-on-specific-links-or-forms
// - https://turbo.hotwired.dev/handbook/frames
// - https://github.com/hotwired/turbo/pull/419
Turbo.setFormMode('optin');
Rails.start();

import "./controllers"

function __removeMeLaterBustingTheCache() {
  return true;
}
